import { SvgIcon, type SvgIconProps } from '@mui/material';

const ExportActiveSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 21 24"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m16.377 7.287.456.418a1 1 0 0 1 .324.737v12.293a1 1 0 0 1-1 1H3.84a1 1 0 0 1-1-1V4.27a1 1 0 0 1 1-1h8.159l1.038.952 1.35-1.35-.388-.37c-1-1-2-1-3-1H3.816c-1.818 0-2.818 1-2.818 2.786v16.429c0 1.285.5 2.785 2.818 2.785H16.18c1.818 0 2.818-1 2.818-2.785V7.574a.77.77 0 0 0-.24-.557L17.674 5.99z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="m12.998 7.502 6.004-6.004m0 0h-3.333m3.333 0V4.83"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);

export default ExportActiveSvg;
