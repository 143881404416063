import { SvgIcon, type SvgIconProps } from '@mui/material';

const CalendarSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="18"
      viewBox="-6 -3 24 24"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.111 1v3.2M4.89 1v3.2M1 7.4h14M2.556 2.6h10.888c.86 0 1.556.716 1.556 1.6v11.2c0 .884-.696 1.6-1.556 1.6H2.556C1.696 17 1 16.284 1 15.4V4.2c0-.884.696-1.6 1.556-1.6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);

export default CalendarSvg;
