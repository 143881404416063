import { SvgIcon, type SvgIconProps } from '@mui/material';

const PreviousPaginationItemSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 7 12"
      width="7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.22 6.72A.75.75 0 0 1 0 6.19v-.38a.77.77 0 0 1 .22-.53L5.36.15a.5.5 0 0 1 .71 0l.71.71a.49.49 0 0 1 0 .7L2.33 6l4.45 4.44a.5.5 0 0 1 0 .71l-.71.7a.5.5 0 0 1-.71 0z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default PreviousPaginationItemSvg;
