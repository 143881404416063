import { SvgIcon, type SvgIconProps } from '@mui/material';

const ExportSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="22"
      viewBox="0 0 18 22"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.76 5.515 13 1c-1-1-2-1-3-1H2.818C1 0 0 1 0 2.786v16.428C0 20.5.5 22 2.818 22h12.364C17 22 18 21 18 19.214V6.072a.77.77 0 0 0-.24-.557m-1.6 13.717a1 1 0 0 1-1 1H2.84a1 1 0 0 1-1-1V2.768a1 1 0 0 1 1-1H11l4.835 4.435a1 1 0 0 1 .324.737z"
        fill="currentColor"
      />
      <path
        d="M6.5 13.5 11 9m0 0H7.667M11 9v3.333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);

export default ExportSvg;
