import { SvgIcon, type SvgIconProps } from '@mui/material';

const LastPaginationItemSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.78 6.718a.75.75 0 0 0 .22-.53v-.38a.77.77 0 0 0-.22-.53L6.64.148a.5.5 0 0 0-.71 0l-.71.71a.49.49 0 0 0 0 .7l4.45 4.44-4.45 4.44a.5.5 0 0 0 0 .71l.71.7a.5.5 0 0 0 .71 0z"
        fill="currentColor"
      />
      <path
        d="M6.78 6.718a.75.75 0 0 0 .22-.53v-.38a.77.77 0 0 0-.22-.53L1.64.148a.5.5 0 0 0-.71 0l-.71.71a.49.49 0 0 0 0 .7l4.45 4.44-4.45 4.44a.5.5 0 0 0 0 .71l.71.7a.5.5 0 0 0 .71 0z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default LastPaginationItemSvg;
