import { SvgIcon, type SvgIconProps } from '@mui/material';

const ResetFiltersSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="26"
      viewBox="-2 -4 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="14" fill="currentColor" r="6" />
      <path
        d="m7.886 12.114-3.772 3.772M7.886 15.886l-3.772-3.772"
        stroke="#fff"
        strokeLinecap="round"
      />
      <g clipPath="url(#a)">
        <path
          d="M23.272 2.636A1 1 0 0 0 22.5 1h-13a1 1 0 0 0-.772 1.636l4.972 6.03v4a1 1 0 0 0 .544.89l2.6 1.334A1 1 0 0 0 18.3 14V8.666z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M7 0h18v16H7z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default ResetFiltersSvg;
