import { SvgIcon, type SvgIconProps } from '@mui/material';

const FilterSvg = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="-3 -4 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.272 2.636A1 1 0 0 0 15.5 1h-13a1 1 0 0 0-.772 1.636L6.7 8.666v4a1 1 0 0 0 .544.89l2.6 1.334A1 1 0 0 0 11.3 14V8.666z"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);

export default FilterSvg;
